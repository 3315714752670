import { apiConfig } from "../../../../config";

interface EmbeddedResponse {
  url: string;
}

interface LoginResponse {
  key: string;
}

interface Pagination {
  total: number;
  total_pages: number;
  next: string;
  previous: string;
}

export interface Annotation {
  id: number;
  document: string;
  queue: string;
  schema: string;
  status: string;
  url: string;
  content: string;
  time_spent: number;
  metadata: Object;
}

export interface Document {
  id: number;
  url: string;
  s3_name: string;
  annotations: string[];
  mime_type: string;
  arrived_at: string;
  original_file_name: string;
  content: string;
  status: string;
  time_spent: number;
  metadata: Object;
}

interface PaginatedDocuments {
  pagination: Pagination;
  results: Document[];
}

export interface PaginatedAnnotations {
  pagination: Pagination;
  results: Annotation[];
}

export class RossumApi {
  private token: string;
  private readonly apiUrl: string;

  constructor() {
    this.token = "";
    // https://api.elis.rossum.ai/docs/#base-url
    this.apiUrl = "https://api.elis.rossum.ai/v1";
  }

  /**
   * Initialises RossumApi by login and storing the token temporarily
   * @param authToken
   */
  async init(authToken: string): Promise<string> {
    if (this.token === "") {
      // @ts-ignore
      const token = await this.login(authToken);
      if (token !== undefined) {
        this.token = token;
        console.log("Success:Login");
      } else {
        console.error("Wrong credentials: cannot resolve rossumToken ");
      }
    } else {
      console.log("Already initialized this instance");
    }
    return this.token;
  }

  async login(authToken: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const url = apiConfig.functionsApiURL + "/rossum/token";
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken
        }
      })
        .then(res => {
          if (res.status < 200 || res.status >= 300) {
            reject(
              new Error(`Cannot login: (${res.status} / ${res.statusText}`)
            );
            return;
          }
          return res.json();
        })
        .then(data => {
          const token = data.token;
          resolve(token);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async prepareIFrameForAnnotation(
    annotationId: number
  ): Promise<EmbeddedResponse> {
    return this.requestBody(
      "POST",
      `/annotations/${annotationId}/start_embedded`,
      {
        locale: "de_DE",
        return_url: "http://localhost:3000/return.html",
        cancel_url: "http://localhost:3000/cancel.html"
      }
    ).then(res => {
      if (res.status < 200 || res.status >= 300) {
        Promise.reject(
          new Error(`Cannot load IFrame: (${res.status} / ${res.statusText}`)
        );
        return;
      }
      return res.json();
    });
  }

  async getAnnotation(annotationId: number): Promise<Annotation> {
    return this.request("GET", `/annotations/${annotationId}`).then(res =>
      res.json()
    );
  }

  async getAnnotations(): Promise<PaginatedAnnotations> {
    return this.request("GET", `/annotations`).then(res => {
      if (res.status < 200 || res.status >= 300) {
        Promise.reject(
          new Error(
            `Cannot fetch Annotations: (${res.status} / ${res.statusText}`
          )
        );
        return;
      }
      return res.json();
    });
  }

  async getDocuments(): Promise<PaginatedDocuments> {
    return this.request("GET", `/documents`).then(res => {
      if (res.status < 200 || res.status >= 300) {
        Promise.reject(
          new Error(
            `Cannot fetch Documents: (${res.status} / ${res.statusText}`
          )
        );
        return;
      }
      return res.json();
    });
  }

  async getDocument(documentId: number): Promise<Document> {
    return new Promise((resolve, reject) => {
      this.request("GET", `/documents/${documentId}`)
        .then(res => {
          if (res.status < 200 || res.status >= 300) {
            reject(
              new Error(
                `Cannot fetch Document: (${res.status} / ${res.statusText}`
              )
            );
          }
          return res.json();
        })
        .then(document => {
          resolve(document);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private async requestBody(method: string, url: string, body: Object) {
    return fetch(this.apiUrl + url, {
      method: method,
      headers: this.buildHeaders(),
      body: JSON.stringify(body)
    });
  }

  async request(method: string, url: string) {
    let finalUrl = url.startsWith(this.apiUrl) ? url : this.apiUrl + url;
    return fetch(finalUrl, {
      method: method,
      headers: this.buildHeaders()
    });
  }

  private buildHeaders() {
    if (this.token === "") {
      return {
        "Content-Type": "application/json",
        Authorization: ""
      };
    } else {
      return {
        "Content-Type": "application/json",
        Authorization: "token " + this.token
      };
    }
  }
}
