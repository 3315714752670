import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import OverviewPage from "../pages/OverviewPage";
import DetailPage from "../pages/DetailPage";
import SettingsPage from "../pages/SettingsPage";
import * as ROUTES from "./routes";
import { useSession } from "../auth";
import PrivateRoute from "./PrivateRoute";
import SegmentContainer from "./SegmentContainer";
import Layout from "../components/Layout";
import NavBar from "../components/NavBar";

interface RouterProps {
  initializing: boolean;
}

function Router({ initializing }: RouterProps) {
  const user = useSession();

  return (
    <BrowserRouter>
      <SegmentContainer>
        <Layout>
          <NavBar />
          {initializing ? null : (
            <Switch>
              <PrivateRoute path={ROUTES.OVERVIEW}>
                <OverviewPage />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.SETTINGS}>
                <SettingsPage />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.DETAIL}>
                <DetailPage />
              </PrivateRoute>
              <Route path={ROUTES.HOME}>
                {user ? <Redirect to={ROUTES.OVERVIEW} /> : <LoginPage />}
              </Route>
            </Switch>
          )}
        </Layout>
      </SegmentContainer>
    </BrowserRouter>
  );
}

export default Router;
