import React from "react";
import styles from "./StatusAvatar.module.css";
import { Status } from "../../storage/cases/types";
import Avatar from "@material-ui/core/Avatar";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface StatusAvatarProps {
  status: Status;
}

function StatusAvatar({ status }: StatusAvatarProps) {
  return status === Status.uploaded || status === Status.processed ? (
    <Avatar className={styles.statusLoading}>
      <HourglassEmptyIcon />
    </Avatar>
  ) : status === Status.checked ? (
    <Avatar className={styles.statusSuccess}>
      <CheckCircleOutlineIcon />
    </Avatar>
  ) : status === Status.error ? (
    <Avatar className={styles.statusError}>
      <ErrorOutlineIcon />
    </Avatar>
  ) : (
    <Avatar>
      <ErrorOutlineIcon />
    </Avatar>
  );
}

export default StatusAvatar;
