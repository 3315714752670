import React from "react";

import "./Main.scss";

type MainProps = {
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
};

function Main({ children, className }: MainProps) {
  return <div className={`main ${className || ""}`}>{children}</div>;
}

export default Main;
