import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

import SearchField from "./components/SearchField";
import UploadButton from "../../components/UploadButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import ProgressBar from "./components/ProgressBar";
import styles from "./OverviewPage.module.scss";
import CaseList from "./components/CaseList";
import { useLimitContext } from "../../storage/limit";
import moment from "moment";
import DatePicker from "./components/DatePicker";
import { useFirstCase } from "../../storage/cases";
import Main from "../../components/Main";

function OverviewPage() {
  const [uploading, setUploading] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [searchPattern, setSearchPattern] = useState("");
  const [dateFilterActive, setDateFilterActive] = useState(false);
  const firstCase = useFirstCase();
  const [startDate, setStartDate] = useState(
    moment(firstCase ? firstCase.createdAt : undefined)
  );
  const [endDate, setEndDate] = useState(moment());
  const limit = useLimitContext();

  useEffect(() => {
    if (!dateFilterActive)
      setStartDate(moment(firstCase ? firstCase.createdAt : undefined));
  }, [firstCase, dateFilterActive]);

  return (
    <Main>
      <LoadingSpinner show={uploading > 0 && totalFiles <= 2} />
      <ProgressBar
        show={totalFiles > 2 && uploading > 0}
        value={(1 - uploading / totalFiles) * 100}
      />
      <header className={styles.header}>
        <div className={styles.headerSection}>
          <SearchField onChange={pattern => setSearchPattern(pattern)} />
        </div>
        <div className={styles.headerSection}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justify={"space-around"}>
              <DatePicker
                date={startDate}
                setDate={date => {
                  setStartDate(date);
                  setDateFilterActive(true);
                }}
                label={"Von"}
              />
              <DatePicker
                date={endDate}
                setDate={date => {
                  setEndDate(date);
                  setDateFilterActive(true);
                }}
                label={"Bis"}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div className={styles.headerSection}>
          <div className={styles.buttonContainer}>
            <UploadButton
              className={styles.uploadButton}
              showQuota={true}
              disabled={limit.isMaxed}
              onUploadStart={numberOfFiles => {
                setUploading(numberOfFiles);
                setTotalFiles(numberOfFiles);
              }}
              onUploadComplete={() => setUploading(uploading => uploading - 1)}
            >
              Neuen Zollfall hochladen
            </UploadButton>
          </div>
        </div>
      </header>
      <CaseList
        filter={{
          pattern: searchPattern,
          range: {
            start: startDate,
            end: endDate
          }
        }}
      />
    </Main>
  );
}

export default OverviewPage;
