import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Tooltip
} from "@material-ui/core";
import styles from "./DetailsList.module.css";
import { CaseData, Status } from "../../../../storage/cases/types";
import StatusAvatar from "../../../../components/StatusAvatar";
import ReportButton from "./components/ReportButton";

interface DetailsListProps {
  currentCase: CaseData | undefined;
}

function DetailsList({ currentCase }: DetailsListProps) {
  return (
    <div className={styles.DetailsList}>
      <div className={styles.header}>
        <StatusAvatar
          status={currentCase?.status ? currentCase?.status : Status.error}
        />
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table
            className={styles.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              {currentCase
                ? Object.entries(currentCase).map(([key, value], i) => (
                    <TableRow>
                      <TableCell>{key}</TableCell>
                      <TableCell>{JSON.stringify(value)}</TableCell>
                    </TableRow>
                  ))
                : null}

              {/* Mockup */}
              <Tooltip
                interactive
                title="Sendungen unter 5 kg = 10,- €,
- Sendung bis 100 kg = Bruttogewicht * 2,5,
- Sendung von 101 kg bis 250 kg = Bruttogewicht * 2,
- Sendung über 250 kg = Bruttogewicht * 1,5"
              >
                <TableRow>
                  <TableCell>
                    Frachtkosten <br />
                    <i>automatisch berechnet</i>
                  </TableCell>
                  <TableCell>
                    {(currentCase?.weight == undefined ||
                    currentCase?.weight == null
                      ? 0
                      : currentCase.weight < 5
                      ? 10
                      : currentCase.weight <= 100
                      ? currentCase.weight * 2.5
                      : currentCase.weight <= 250
                      ? currentCase.weight * 2
                      : currentCase.weight * 1.5
                    ).toString() + " €"}
                  </TableCell>
                </TableRow>
              </Tooltip>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={styles.footer}>
        <ReportButton
          className={styles.ReportButton}
          caseId={currentCase?.id}
        />
      </div>
    </div>
  );
}

export default DetailsList;
