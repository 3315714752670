import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import styles from "./SearchField.module.scss";

interface SearchFieldProps {
  onChange: (pattern: string) => void;
}

function SearchField({ onChange }: SearchFieldProps) {
  return (
    <div className={styles.container}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={"Frachtbriefnummer..."}
        className={styles.input}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
}

export default SearchField;
