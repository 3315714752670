import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Snackbar
} from "@material-ui/core";
import { useAuth } from "../../../../auth";
import firebase from "firebase";

function ChangePasswordDialog() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const { user } = useAuth();

  async function changePassword() {
    if (
      user !== null &&
      user.email !== null &&
      newPassword.length > 0 &&
      newPassword === repeatPassword &&
      firebase.auth() !== null &&
      firebase.auth().currentUser !== null
    ) {
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          oldPassword
        );
        await firebase
          .auth()
          ?.currentUser?.reauthenticateWithCredential(credential);
        await firebase.auth()?.currentUser?.updatePassword(newPassword);
        showSnackBar("Passwort erfolgreich geändert.");
        setDialogOpen(false);
      } catch (error) {
        showSnackBar(error.toString());
      }
    } else {
      showSnackBar("Neues Passwort nicht gültig.");
    }
  }

  function showSnackBar(text: string) {
    setSnackBarText(text);
    setSnackBarOpen(true);
  }

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={event => setDialogOpen(true)}
      >
        Passwort ändern
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Passwort ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              <TextField
                onChange={event => setOldPassword(event.target.value)}
                variant="outlined"
                type="password"
                label="Altes Passwort"
              />
            </p>
            <p>
              <TextField
                onChange={event => setNewPassword(event.target.value)}
                variant="outlined"
                type="password"
                label="Neues Passwort"
              />
            </p>
            <p>
              <TextField
                onChange={event => setRepeatPassword(event.target.value)}
                variant="outlined"
                type="password"
                label="Passwort wiederholen"
              />
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setDialogOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={event => {
              changePassword();
            }}
            color="primary"
            autoFocus
          >
            Passwort ändern
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        onClose={event => setSnackBarOpen(false)}
        message={snackBarText}
      />
    </Fragment>
  );
}

export default ChangePasswordDialog;
