import { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { getUrl } from "../utils";

export enum UserRole {
  CustomsAgent = 0,
  Digicust = 1
}

export interface User extends firebase.User {
  role: UserRole;
}

type UserOrNull = User | null;

type UserContextProps = {
  user: UserOrNull;
};

export const UserContext = createContext<UserContextProps>({
  user: null
});

export const useSession = () => {
  const { user } = useContext(UserContext);
  return user;
};

type AuthState = {
  initializing: boolean;
  user: UserOrNull;
};

export const useAuth = (): AuthState => {
  const [state, setState] = useState(
    (): AuthState => {
      const user = firebase.auth().currentUser;
      return {
        initializing: !user,
        user: user
          ? Object.assign({}, user, { role: UserRole.CustomsAgent })
          : null
      };
    }
  );

  function onChange(user: firebase.User | null): void {
    // console.log("user change", user);

    if (user) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const userData = doc.data();

            if (
              userData &&
              typeof userData.role !== "undefined" &&
              Object.values(UserRole).indexOf(userData.role) !== -1
            ) {
              window.analytics.identify(user.uid, {
                email: user.email!
              });
              setState({
                initializing: false,
                user: Object.assign({}, user, { role: userData.role }) as User
              });
              return;
            } else {
              console.error(`User role either not available or not valid`);
            }
          } else {
            console.error(`User (${user.uid}) doesn't exist in Firestore.`);
          }

          // set user to null to make sure they get redirected to login page and cannot access other pages
          setState({
            initializing: false,
            user: null
          });
        });
    } else {
      setState({
        initializing: false,
        user: null
      });
      window.analytics.reset();
    }
  }

  useEffect(() => {
    // returns unsubscribe function which is used by react to clean up on unmount
    return firebase.auth().onAuthStateChanged(onChange);
  }, []);

  return state;
};

type Credentials = {
  email: string;
  password: string;
};

type LoginError = {
  code: string | null;
  message: string | null;
};

interface LoginHook {
  error: LoginError;
  login: (creads: Credentials) => any;
}

export const useLogin: () => LoginHook = () => {
  const [error, setError] = useState({
    code: null,
    message: null
  });

  const login: (creds: Credentials) => any = creds => {
    const { email, password } = creds;
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        const { code, message } = error;
        setError({ code, message });
      });
  };

  // Todo: reset error after successful login (maybe in user change listener?)

  return {
    error,
    login
  };
};

export const useLogout: () => () => any = () => {
  const logout = () => {
    return firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  };

  return logout;
};

export const usePasswordReset: () => (email: string) => any = () => {
  const passwordReset: (email: string) => any = email => {
    firebase.auth().languageCode = "de";
    return firebase.auth().sendPasswordResetEmail(email, { url: getUrl() });
  };

  return passwordReset;
};
