import React from "react";

import "./SubmitButton.scss";

interface SubmitButtonProps {
  children: React.ReactNode[] | React.ReactNode;
}

function SubmitButton({ children }: SubmitButtonProps) {
  return (
    <button type="submit" className="submit-button">
      {children}
    </button>
  );
}

export default SubmitButton;
