import React from "react";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

import styles from "./DatePicker.module.scss";

interface DatePickerProps {
  date: moment.Moment;
  setDate: (date: moment.Moment) => void;
  label: string;
}

function DatePicker({ date, setDate, label }: DatePickerProps) {
  return (
    <div className={styles.container}>
      <KeyboardDatePicker
        disableToolbar
        value={date}
        // @ts-ignore
        onChange={setDate}
        variant="inline"
        format={"DD.MM.YYYY"}
        label={label}
      />
    </div>
  );
}

export default DatePicker;
