import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";

import { usePasswordReset } from "../../../../auth";
import FormField from "../FormField";
import SubmitButton from "../SubmitButton";
import "./ResetForm.scss";

interface ResetFormProps {
  onBackClick: () => void;
}

function ResetForm({ onBackClick }: ResetFormProps) {
  const [passwordSent, setPasswordSent] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const passwordReset = usePasswordReset();

  const onSubmit: (data: FieldValues) => void = data => {
    window.analytics.track("Password Reset Sent", {
      email: data.email
    });
    passwordReset(data.email)
      .then(() => {
        setPasswordSent(true);
      })
      .catch((error: any) => {
        console.error("Error during password reset:", error);
        if (error.code === "auth/user-not-found") {
          setError(error.message);
        }
        setPasswordSent(false);
      });
  };

  return (
    <div className="reset-form">
      <h1 className={"reset-form__heading"}>
        <button className={"reset-form__back-button"} onClick={onBackClick}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
        </button>
        Reset Password
      </h1>
      <div className="reset-form__text">
        {passwordSent
          ? "We sent you an email with instructions on how to reset your password."
          : "Please type in your email so we can send you instructions on how to reset your password"}
      </div>

      {passwordSent ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="reset-form__error-msg">{error}</div>
          <FormField
            error={errors.email}
            register={() =>
              register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address"
                }
              })
            }
            placeholder={"Type your email"}
            label={"Email"}
            hideLabel
          />

          <SubmitButton>Reset Password</SubmitButton>
        </form>
      )}
    </div>
  );
}

export default ResetForm;
