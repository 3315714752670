import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface SegmentContainerProps {
  children: React.ReactNode[] | React.ReactNode;
}

function SegmentContainer({ children }: SegmentContainerProps) {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState("");

  // the initial page trigger
  useEffect(() => {
    window.analytics.page(history.location.pathname);
  }, [history]);

  useEffect(() => {
    const pathname = history.location.pathname;
    setCurrentPath(pathname);

    const routeChange = (route: any) => {
      const newPathname = route.pathname;
      if (newPathname !== currentPath) {
        window.analytics.page(newPathname);
        setCurrentPath(newPathname);
      }
    };

    return history.listen(routeChange);
  }, [history, currentPath]);

  return <>{children}</>;
}

export default SegmentContainer;
