import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import Router from "./router";
import { fbConfig } from "./config";
import { useAuth, UserContext, UserRole } from "./auth";
import { CasesContext } from "./storage/cases";
import { CaseData, Status } from "./storage/cases/types";
import { LimitContext, useLimit } from "./storage/limit";

// To make sure the cypress/firebase setup uses the same firebase app object
// @ts-ignore
if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig);
}
const db = firebase.firestore();

function App() {
  const { initializing, user } = useAuth();
  const limitState = useLimit(user);
  const [cases, setCases] = useState<CaseData[]>(new Array<CaseData>());
  useEffect(() => {
    if (user !== null) {
      if (user.role === UserRole.Digicust) {
        return db.collectionGroup("cases").onSnapshot(snap => {
          const cases: Array<CaseData> = [];
          snap.forEach(doc => {
            const data = doc.data();
            cases.push(
              Object.assign({}, data, {
                id: doc.id,
                createdAt: data.createdAt || Date.now(),
                status: data.status || Status.error
              }) as CaseData
            );
          });
          setCases(cases);
        });
      } else {
        return db
          .collection("users")
          .doc(user.uid)
          .collection("cases")
          .onSnapshot(caseSnapshots => {
            const cases: Array<CaseData> = [];
            caseSnapshots.forEach(async caseSnapshot => {
              const data = caseSnapshot.data();
              cases.push(
                Object.assign({}, data, {
                  id: caseSnapshot.id,
                  createdAt: data.createdAt || Date.now(),
                  status: data.status || Status.error
                }) as CaseData
              );
            });
            setCases(cases);
          });
      }
    }
    return () => {
      // console.log("unsubscribing empty case listener");
    };
  }, [user]);

  return (
    <UserContext.Provider value={{ user }}>
      <LimitContext.Provider value={limitState}>
        <CasesContext.Provider value={cases}>
          <Router initializing={initializing} />
        </CasesContext.Provider>
      </LimitContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
