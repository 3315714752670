import { useState } from "react";
import firebase from "firebase/app";

import { useSession } from "../auth";
import { asyncForEach } from "../utils";
import { Status } from "./cases/types";

export const useUpload = () => {
  const [error, setError] = useState({ code: 0, message: "" });
  const [loading, setLoading] = useState(false);
  const user = useSession() as firebase.User;
  const upload = async (replaceId: String | null, files: Array<File>) => {
    setLoading(true);
    try {
      const { id } = await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("cases")
        .add({
          status: "init",
          replaceId: replaceId || null,
          createdAt: Date.now(),
          processedAt: null,
          sentAt: null,
          wayBillNumber: null,
          incoterm: null,
          invoiceNumbers: [],
          weight: null,
          weightUnit: null,
          totalValue: null,
          currency: null,
          tariffNumbers: [],
          procedure: null,
          fromAddress: null,
          toAddress: null,
          checkpoint: null,
          type: null,
          fileNames: []
        });
      const fileNames: Array<string> = [];
      await asyncForEach(files, async (file: File) => {
        const fileName = `${user.uid}/${Date.now()}-${file.name}`;
        await firebase
          .storage()
          .ref(fileName)
          .put(file, {
            customMetadata: { uid: user.uid, fileName: file.name, caseId: id }
          });
        fileNames.push(fileName);
      });
      await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("cases")
        .doc(id)
        .update({ fileNames, status: Status.uploaded });
      setError({ code: 0, message: "" });
    } catch (err) {
      console.log(err);
      setError({ code: err.code, message: err.message });
    }
    setLoading(false);
  };
  return { upload, error, loading };
};
