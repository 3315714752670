import React from "react";
import { useParams } from "react-router-dom";
import styles from "./DetailPage.module.css";
import DetailsList from "./components/DetailsList";
import { useCase } from "../../storage/cases";
import RossumViewer from "./components/RossumViewer";
import { RouteComponentProps, withRouter } from "react-router";
import PdfViewer from "./components/PdfViewer";

function DetailPage(props: RouteComponentProps) {
  const { id } = useParams();
  const currentCase = useCase(id);

  console.info("currentCase?.status: " + currentCase?.status);

  return currentCase?.status === "uploaded" ? (
    <div className={styles.rossumPage}>
      <RossumViewer
        currentCase={currentCase}
        onConfirm={() => {
          console.log("TODO: implement confirm");
          props.history.push("/overview");
        }}
        onReturn={() => {
          console.log("TODO: implement onReturn");
          props.history.push("/overview");
        }}
      />
    </div>
  ) : (
    <div className={styles.detailPage}>
      <p>Test</p>
      <PdfViewer currentCase={currentCase} />
      <DetailsList currentCase={currentCase} />
    </div>
  );
}

export default withRouter(DetailPage);
