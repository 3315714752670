import React, { useState, useEffect } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import styles from "./PdfViewer.module.css";
import { CaseData } from "../../../../storage/cases/types";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { useSession } from "../../../../auth";
import { asyncForEach, asyncMap } from "../../../../utils";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/" + pdfjs.version + "/pdf.worker.js";

interface iOnLoadSuccess {
  numPages: number;
}

interface PdfViewerProps {
  currentCase: CaseData | undefined;
}

function PdfViewer({ currentCase }: PdfViewerProps) {
  const [numPages, setNumPages] = useState<number>(0);
  const user = useSession();
  const storage = firebase.storage();
  const db = firebase.firestore();
  // TODO: Interface for Document
  const [documents, setDocuments] = useState<any[]>(new Array<any>());

  useEffect(() => {
    if (currentCase?.id !== undefined) {
      return db
        .collection(
          "users/" + user?.uid + "/cases/" + currentCase?.id + "/documents"
        )
        .onSnapshot(async snap => {
          const documents: Array<any> = [];
          snap.forEach(doc => {
            const data = doc.data();
            documents.push(
              Object.assign({}, data, {
                id: doc.id
              })
            );
          });
          setDocuments(
            await asyncMap(documents, async (document: any) => {
              const downloadURL = await storage
                .ref(document?.storagePath)
                .getDownloadURL();
              return { ...document, downloadURL };
            })
          );
        });
    }
  }, [user, currentCase]);

  return (
    <div className={styles.root}>
      {documents?.map(document => (
        <React.Fragment key={document?.fileName.toString()}>
          {/* <p>{document?.fileName}</p> */}
          <Document
            file={document?.downloadURL}
            onLoadSuccess={(event: iOnLoadSuccess) => {
              setNumPages(event.numPages);
            }}
          >
            {new Array(numPages).fill("").map((_, index) => {
              return (
                <Page
                  className={styles.pdfviewerPage}
                  width={700}
                  pageNumber={index + 1}
                ></Page>
              );
            })}
          </Document>
        </React.Fragment>
      ))}
    </div>
  );
}

export default PdfViewer;
