import React from "react";
import { LinearProgress, CircularProgress, Button } from "@material-ui/core";

import styles from "./UploadButton.module.css";
import { useLimitContext } from "../../storage/limit";
import { useUpload } from "../../storage";

interface UploadButtonProps {
  onUploadStart: (nFiles: number) => void;
  onUploadComplete: () => void;
  replaceId?: string;
  disabled?: boolean;
  showQuota?: boolean;
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}

function UploadButton({
  onUploadStart,
  disabled,
  onUploadComplete,
  showQuota,
  children,
  replaceId,
  className
}: UploadButtonProps) {
  const limitState = useLimitContext();
  const { upload, loading } = useUpload();

  return (
    <div className={styles.wrapper}>
      <Button
        component="label"
        size="large"
        color="primary"
        disabled={loading}
        className={styles.button}
      >
        Neuen Zollfall hochladen
        {loading && <CircularProgress className={styles.progress} size={24} />}
        <input
          style={{ display: "none" }}
          type="file"
          multiple={true}
          onChange={e => {
            if (e.target.files !== null) {
              upload(null, Array.from(e.target.files));
            }
          }}
        />
      </Button>
      {showQuota && (
        <div className={styles.quota}>
          <LinearProgress
            variant="determinate"
            value={Math.min(
              100,
              Math.floor((limitState.amount * 100) / limitState.max)
            )}
          />
          <span>
            {!limitState.isMaxed
              ? `${limitState.amount} von ${limitState.max} verbraucht`
              : "Tägliches Kontingent verbraucht"}
          </span>
        </div>
      )}
    </div>
  );
}

export default UploadButton;
