import React from "react";

import "./LoadingSpinner.scss";

type LoadingSpinnerProps = {
  show: boolean;
};

function LoadingSpinner({ show }: LoadingSpinnerProps) {
  return (
    <div
      className={`loading-spinner ${!show ? "loading-spinner--hidden" : ""}`}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default LoadingSpinner;
