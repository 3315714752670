import { createContext, useContext, useState } from "react";
import { CaseData, Status } from "./types";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import { useSession } from "../../auth";

export const CasesContext = createContext(new Array<CaseData>());

export const useCases = () => {
  return useContext(CasesContext);
};

export const useCase: (id: string | undefined) => CaseData | undefined = id => {
  const cases = useCases();
  return cases.find(c => c.id === id);
};

export const useFirstCase: () => CaseData | null = () => {
  const cases = useCases();
  return cases.length > 0
    ? cases.reduce((min, c) => (min.createdAt > c.createdAt ? c : min))
    : null;
};
export const useAddReport = () => {
  const [loading, setLoading] = useState(false);
  const db = firebase.firestore();
  const user = useSession();
  const addReport = async (caseId: string | undefined, problem: string) => {
    setLoading(true);
    if (user !== null && caseId !== undefined) {
      await db
        .collection("users")
        .doc(user.uid)
        .collection("cases")
        .doc(caseId)
        .collection("reports")
        .add({
          createdAt: Date.now(),
          createdBy: user.uid,
          description: problem
        });
    }
    setLoading(false);
  };

  return { addReport, loading };
};

export const useAbortCase = () => {
  const user = useSession();
  const db = () => {
    return firebase.firestore();
  };
  const abortCase = (caseId: string) => {
    if (user !== null) {
      db()
        .collection("users")
        .doc(user.uid)
        .collection("cases")
        .doc(caseId)
        .update({ status: Status.aborted });
    }
  };
  return { abortCase };
};
