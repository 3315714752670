import React, { useState } from "react";
import { StyleContext } from "../../styles";
import "./Layout.scss";

type LayoutProps = {
  children: React.ReactNode[] | React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  const [className, setClassName] = useState("");

  return (
    <StyleContext.Provider
      value={{ setLayoutClass: name => setClassName(name) }}
    >
      <div className={`layout ${className}`}>{children}</div>
    </StyleContext.Provider>
  );
}

export default Layout;
