import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { segmentKey } from "./config";

declare global {
  interface Window {
    analytics: {
      track(name: string, props?: { [key: string]: string | boolean }): void;
      identify(name: string, props?: { [key: string]: string }): void;
      reset(): void;
      page(name: string, props?: { [key: string]: string }): void;
      load(key: string): void;
    };
  }
}

window.analytics.load(segmentKey!);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
