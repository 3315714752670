import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSession, useLogout } from "../../auth";
import styles from "./NavBar.module.css";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import { AccountCircle, ChevronLeft } from "@material-ui/icons";
import { SETTINGS, OVERVIEW, HOME } from "../../router/routes";

function NavBar() {
  const user = useSession();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const logout = useLogout();
  const history = useHistory();
  const location = useLocation().pathname;

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={styles.AppBar} position="static">
      <Toolbar className={styles.Toolbar}>
        <Typography variant="h6" className={styles.title}>
          {location === OVERVIEW || location === HOME ? (
            "Digicust"
          ) : (
            <React.Fragment>
              <IconButton
                onClick={() => history.push(OVERVIEW)}
                edge="start"
                color="inherit"
              >
                <ChevronLeft />
              </IconButton>
              {location.startsWith("/detail")
                ? "Details"
                : location === SETTINGS
                ? "Einstellungen"
                : "Digicust"}
            </React.Fragment>
          )}
        </Typography>
        {user && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push(SETTINGS);
                  handleClose();
                }}
              >
                Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.analytics.track("User Logout");
                  logout();
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
