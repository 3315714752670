export enum RossumImportStatus {
  Importing = "importing",
  FailedImport = "failedimport",
  ToReview = "to_review",
  Reviewing = "reviewing",
  Exporting = "exporting",
  Exported = "exported",
  FailedExport = "failed_export",
  Postponed = "postponed",
  Deleted = "deleted",
  Purged = "purged",
  Confirmed = "confirmed"
}
