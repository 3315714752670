import React from "react";

import "./FormField.scss";

interface FormFieldError {
  message: string;
}

interface FormFieldProps {
  error: FormFieldError | null;
  register: () => any;
  label: string;
  name?: string;
  placeholder?: string;
  type?: string;
  children?: React.ReactNode[] | React.ReactNode;
  hideLabel?: boolean;
}

function FormField({
  error,
  register,
  name,
  label,
  type,
  placeholder,
  children,
  hideLabel
}: FormFieldProps) {
  return (
    <div className="form-field">
      {hideLabel ? null : (
        <label htmlFor={name} className="form-field__label">
          {label}:
        </label>
      )}
      <input
        className={"form-field__input"}
        type={type || "text"}
        name={name || label.toLowerCase()}
        placeholder={placeholder}
        ref={register()}
      />
      {error ? (
        <div className="form-field__error-msg">{error.message}</div>
      ) : null}
      {children}
    </div>
  );
}

export default FormField;
