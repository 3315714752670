import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useLogin } from "../../../../auth";
import FormField from "../FormField";

import "./LoginForm.scss";
import SubmitButton from "../SubmitButton";

interface LocationState {
  from: string;
}

interface LoginFormProps {
  onResetClick: () => void;
}

function LoginForm({ onResetClick }: LoginFormProps) {
  const { register, handleSubmit, errors } = useForm();
  const { error, login } = useLogin();
  // const location = useLocation();
  // const history = useHistory();

  const onSubmit: (data: FieldValues) => void = data => {
    window.analytics.track("User Login", {
      email: data.email
    });
    login({
      email: data.email,
      password: data.password
    }).then(() => {
      // Todo: redirect to previous page
      // const { from } = location.state as LocationState || { from: { pathname: "/" } };
      // history.replace(from);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <h1 className={"login-form__heading"}>Login</h1>
      {error.message ? (
        <div className="login-form__server-error-msg">{error.message}</div>
      ) : null}

      <FormField
        error={errors.email}
        label={"Email"}
        register={() =>
          register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "invalid email address"
            }
          })
        }
        placeholder={"Type your email"}
      />

      <FormField
        error={errors.password}
        label={"Password"}
        type={"password"}
        register={() => register({ required: "Required" })}
        placeholder={"Type your password"}
      >
        <button
          onClick={onResetClick}
          className="login-form__reset-link"
          type="button"
        >
          Forgot Password?
        </button>
      </FormField>

      <SubmitButton>Login</SubmitButton>
    </form>
  );
}

export default LoginForm;
