import React, { useEffect, useState } from "react";
import styles from "./RossumViewer.module.css";
import { CaseData, CaseDocument } from "../../../../storage/cases/types";
import { RossumApi } from "../RossumApi/RossumApi";
import { useAuth } from "../../../../auth";
import firebase from "firebase";
import { RossumImportStatus } from "../../../../model/rossum-import-status";
import PdfViewer from "../PdfViewer";
import DetailsList from "../DetailsList";

interface RossumViewerProps {
  currentCase: CaseData | undefined;
  onConfirm: () => void;
  onReturn: () => void;
}

function RossumViewer(props: RossumViewerProps) {
  const caseData = props.currentCase;
  const [api] = useState<RossumApi>(new RossumApi());
  const { user } = useAuth();
  const db = firebase.firestore();
  const [ready, setReady] = useState<boolean>(false);
  const [currentCaseDocument, setCurrentCaseDocument] = useState<
    CaseDocument
  >();
  const [message, setMessage] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [useViewer, setUseViewer] = useState<boolean>(false);

  useEffect(() => {
    if (!ready) {
      // @ts-ignore
      firebase
        .auth()
        .currentUser.getIdToken(false)
        .then(authToken => {
          api
            .init(authToken)
            .then(token => {
              console.log("initialized rossum");
              setReady(true);
            })
            .catch(err => {
              setErrorText("Verbindung zur Datenextrahierung schlug fehl.");
              console.error(err);
            });
        });
    }
  }, [ready, api]);

  useEffect(() => {
    if (ready && user && caseData && !currentCaseDocument && !errorText) {
      console.log("Prepare currentCaseDocument with Rossum");

      return db
        .collection("users")
        .doc(user.uid)
        .collection("cases")
        .doc(caseData?.id)
        .collection("documents")
        .onSnapshot(snap => {
          const caseDocuments: Array<CaseDocument> = [];
          snap.forEach(doc => {
            const data = doc.data();
            caseDocuments.push(Object.assign({}, data, {}) as CaseDocument);
          });
          if (caseDocuments.length > 0) {
            const rossumAnnotationId = caseDocuments[0].rossumAnnotationId;
            const rossumStatus = caseDocuments[0].rossumStatus;
            if (rossumAnnotationId) {
              console.log(
                `rossumAnnotationId is ${rossumAnnotationId}, status ${rossumStatus}`
              );
              setCurrentCaseDocument(caseDocuments[0]);
            } else {
              setErrorText(
                "Dokument wurde noch nicht vollständig verarbeitet!"
              );
            }
          } else {
            setErrorText("Dokument-Verarbeitung noch ausständig!");
          }
        });
    }
  }, [db, ready, user, caseData, currentCaseDocument, errorText]);

  useEffect(() => {
    if (ready && currentCaseDocument) {
      if (currentCaseDocument.rossumAnnotationId) {
        if (isCaseDocumentLoadable()) {
          console.log(
            "Start loading iframe for:" + currentCaseDocument.rossumAnnotationId
          );
          loadDocument(Number(currentCaseDocument.rossumAnnotationId));
        } else if (isCaseDocumentDone()) {
          setUseViewer(true);
        } else {
          setErrorText(
            "Dokument kann noch nicht validiert werden, bitte später nochmal probieren"
          );
        }
      } else {
        setErrorText(
          "Bitte warten. Dokument wurde noch nicht vollständig verarbeitet!"
        );
      }
    }
  }, [ready, currentCaseDocument]);

  useEffect(() => {
    if (message !== "") {
      if (message === "confirm") {
        setCurrentCaseDocument(undefined);
        props.onConfirm();
      } else {
        props.onReturn();
      }
      setMessage("");
    }
  }, [props, message]);

  useEffect(() => {
    if (errorText !== "") {
      console.error(errorText);
    }
  }, [errorText]);

  function isCaseDocumentLoadable() {
    return (
      currentCaseDocument?.rossumStatus === RossumImportStatus.ToReview ||
      currentCaseDocument?.rossumStatus === RossumImportStatus.Reviewing ||
      currentCaseDocument?.rossumStatus === RossumImportStatus.Postponed ||
      currentCaseDocument?.rossumStatus === RossumImportStatus.Importing ||
      currentCaseDocument?.rossumStatus === RossumImportStatus.Confirmed
    );
  }

  function isCaseDocumentDone() {
    return currentCaseDocument?.rossumStatus === RossumImportStatus.Exported;
  }

  function receiveMessage(event: { data: string }) {
    /* XXX: Verify origin. */
    console.log(">>> received iframe message:", event);
    if (event.data && event.data === "return") {
      /* Confirm. */
      console.log("Confirm iframe");
      setMessage("confirm");
    } else {
      console.log("Cancel iframe");
      setMessage("cancel");
    }
    window.removeEventListener("message", receiveMessage, false);
  }

  function loadDocument(annotationId: number) {
    api
      .prepareIFrameForAnnotation(annotationId)
      .then(resp => {
        if (resp.url !== undefined) {
          console.log(`Open url ${resp.url}`);
          let iframe = document.getElementById("iframe");
          iframe?.setAttribute("src", resp.url);
          window.addEventListener("message", receiveMessage, false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  return (
    <div className={styles.root}>
      {useViewer ? (
        <div className={styles.viewer}>
          <PdfViewer currentCase={caseData} />
          <DetailsList currentCase={caseData} />
        </div>
      ) : currentCaseDocument ? (
        <iframe title="iframe" id="iframe" src="" />
      ) : (
        <div className={styles.container}>
          {errorText !== "" ? (
            <p className={styles.center}>
              <b className={styles.error}>{errorText}</b>
            </p>
          ) : (
            <p className={styles.center}> Lade Dokumente ... </p>
          )}
        </div>
      )}
    </div>
  );
}

export default RossumViewer;
