import { RossumImportStatus } from "../../model/rossum-import-status";

export enum Status {
  init = "init",
  uploaded = "uploaded",
  processed = "processed",
  checked = "checked",
  error = "error",
  aborted = "aborted"
}

enum WeightUnit {
  kilogram = 1
}

enum CaseType {
  import = 1,
  export = 2
}

interface CaseItemSpecialMeasure {
  value: number;
  unit: string;
}

interface CaseItem {
  tariffNumber: string;
  description: string;
  specialMeasure: Array<CaseItemSpecialMeasure>;
  weight: number;
  weightUnit: WeightUnit;
  value: number;
  invoiceNumbers: Array<string>;
  additionalCosts: Array<string>;
  procedure: string;
}

export interface CaseDocument {
  fileName?: string;
  rossumAnnotationId?: string;
  rossumStatus?: RossumImportStatus;
  storagePath?: string;
  filename?: string;
  uploadedAt?: string;
}

export interface CaseData {
  id: string; // internal ID
  status: Status;
  createdAt: number; // timestamp
  wayBillNumber?: string;
  incoterm?: string;
  invoiceNumbers?: Array<string>;
  weight?: number;
  weightUnit?: WeightUnit;
  totalValue?: number;
  currency?: string;
  tariffNumbers?: Array<string>;
  procedure?: string;
  fromAddress?: string;
  toAddress?: string;
  checkpoint?: string;
  type?: CaseType;
  items?: Array<CaseItem>;
  fileNames?: Array<String>;
  errorMessage?: string;
  documents?: Array<CaseDocument>;
  rossumDocumentId?: string;
  rossumAnnotationId?: string;
}

export interface Report {
  description?: string;
  createdAt?: number;
  createdBy?: string;
}
