const fbConfigProduction = {
  apiKey: process.env.REACT_APP_FB_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN_PROD,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL_PROD,
  projectId: process.env.REACT_APP_FB_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_FB_APP_ID_PROD,
  functionsApiURL: process.env.REACT_APP_FB_FUNCTIONSAPI_URL_STAGING
};

const fbConfigStaging = {
  apiKey: process.env.REACT_APP_FB_API_KEY_STAGING,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN_STAGING,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL_STAGING,
  projectId: process.env.REACT_APP_FB_PROJECT_ID_STAGING,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET_STAGING,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID_STAGING,
  appId: process.env.REACT_APP_FB_APP_ID_STAGING,
  functionsApiURL: process.env.REACT_APP_FB_FUNCTIONSAPI_URL_STAGING
};

export const apiConfig =
  process.env.NODE_ENV === "production"
    ? {
        functionsApiURL:
          "https://us-central1-digicust-staging.cloudfunctions.net/api"
      }
    : {
        functionsApiURL:
          "https://us-central1-digicust-staging.cloudfunctions.net/api"
      };

export const fbConfig =
  process.env.NODE_ENV === "production" ? fbConfigProduction : fbConfigStaging;

export const segmentKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SEGMENT_KEY_PROD
    : process.env.REACT_APP_SEGMENT_KEY_STAGING;
