import { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { User } from "../auth";

interface LimitState {
  amount: number;
  max: number;
  isMaxed: boolean;
}

const initialState = {
  amount: 0,
  max: 0,
  isMaxed: false
};

export const LimitContext = createContext<LimitState>(initialState);

export const useLimitContext = () => {
  return useContext(LimitContext);
};

export const useLimit = (user: User | null) => {
  const [limitState, setLimitState] = useState<LimitState>(initialState);

  useEffect(() => {
    // user is required because the limit is only readable for authenticated request.
    if (!user) return;

    return firebase
      .firestore()
      .collection("limit")
      .doc("cases")
      .onSnapshot(snapshot => {
        const data = snapshot.data() as {
          amount: number;
          max: number;
          last_reset: number;
        };

        let amount: number;
        // already a new day but db is not yet updated
        if (
          new Date(data.last_reset).toDateString() !== new Date().toDateString()
        ) {
          amount = 0;
        } else {
          amount = data.amount;
        }

        setLimitState({ max: data.max, isMaxed: amount >= data.max, amount });
      });
  }, [user]);

  return limitState;
};
