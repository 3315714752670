import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import { useAbortCase } from "../../../../../../storage/cases";

type AbortButtonProps = {
  className: string;
  caseId: string;
};

function AbortButton({ className, caseId }: AbortButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { abortCase } = useAbortCase();
  return (
    <Fragment>
      <Button
        className={className}
        variant="outlined"
        color="primary"
        onClick={event => setDialogOpen(true)}
      >
        Abbrechen
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Bearbeitung abbrechen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Fall '{caseId}' wird abgebrochen. Diese Aktion kann nicht rückgängig
            gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => setDialogOpen(false)} color="primary">
            Zurück
          </Button>
          <Button
            onClick={event => {
              abortCase(caseId);
            }}
            color="primary"
            autoFocus
          >
            Fall unwiderruflich abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AbortButton;
