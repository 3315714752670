import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

interface ProgressBarProps {
  value: number;
  show: boolean;
}

function ProgressBar({ value, show }: ProgressBarProps) {
  return show ? (
    <div>
      <LinearProgress variant={"determinate"} value={value} />
    </div>
  ) : null;
}

export default ProgressBar;
