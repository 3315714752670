import React from "react";
import List from "@material-ui/core/List";
import { useCases } from "../../../../storage/cases";
import CaseListItem from "../CaseListItem";
import { Status, CaseData } from "../../../../storage/cases/types";
import moment from "moment";

interface CaseListProps {
  filter: {
    range: {
      start: moment.Moment;
      end: moment.Moment;
    };
    pattern: string;
  };
}

function CaseList({ filter }: CaseListProps) {
  const cases = useCases();
  const { range, pattern } = filter;

  return (
    <List dense>
      {cases
        .filter(c =>
          moment(c.createdAt).isBetween(range.start, range.end, "day", "[]")
        )
        .filter(
          c =>
            !pattern ||
            (c.wayBillNumber && c.wayBillNumber.indexOf(pattern) !== -1)
        )
        .filter(c => c.status !== Status.aborted && c.status !== Status.init)
        .sort((a, b) => b.createdAt - a.createdAt)
        .sort(
          (a, b) =>
            Number(b.status === Status.error) -
            Number(a.status === Status.error)
        )
        .map((caseData: CaseData) => {
          return (
            <CaseListItem
              key={"caseListItem" + caseData.id}
              caseData={caseData}
            />
          );
        })}
    </List>
  );
}

export default CaseList;
