import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useSession } from "../../auth";
import * as ROUTES from "../routes";

type PrivateRouteProps = {
  children: React.ReactNode;
  [rest: string]: any;
};

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const user = useSession();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
