import { createContext, useContext } from "react";

type StyleContextProps = {
  setLayoutClass: (newClass: string) => void;
};

export const StyleContext = createContext<StyleContextProps>({
  setLayoutClass: () => {}
});

export const useSetLayoutClass = () => {
  const { setLayoutClass } = useContext(StyleContext);
  return setLayoutClass;
};
