import React from "react";

import "./SettingsPage.scss";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import Main from "../../components/Main";

function SettingsPage() {
  return (
    <Main className={"settings-page"}>
      <h2>Account</h2>
      <p>
        <ChangePasswordDialog />
      </p>
    </Main>
  );
}

export default SettingsPage;
