import React, { useState, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField
} from "@material-ui/core";
import styles from "./ReportButton.module.css";
import { useAddReport } from "../../../../../../storage/cases";

type ReportButtonProps = {
  className: string | undefined;
  caseId: string | undefined;
};

function ReportButton({ className, caseId }: ReportButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [problem, setProblem] = useState("");
  const { addReport, loading } = useAddReport();
  return (
    <Fragment>
      <Button
        className={className}
        variant="outlined"
        color="primary"
        onClick={event => setDialogOpen(true)}
      >
        Problem melden
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Problem melden</DialogTitle>
        <DialogContent className={styles.DialogContent}>
          <DialogContentText id="alert-dialog-description">
            <TextField
              disabled={loading}
              label="Problembeschreibung"
              multiline
              fullWidth
              rows={4}
              defaultValue={problem}
              onChange={event => setProblem(event.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Zurück
          </Button>
          <Button
            onClick={async () => {
              await addReport(caseId, problem);
              setDialogOpen(false);
              setProblem("");
            }}
            color="primary"
            autoFocus
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ReportButton;
