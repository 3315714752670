import React from "react";
import styles from "./CaseListItem.module.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { Link } from "react-router-dom";
import AbortButton from "./components/AbortButton";
import { Status, CaseData } from "../../../../storage/cases/types";
import StatusAvatar from "../../../../components/StatusAvatar";

type CaseListItemProps = {
  caseData: CaseData;
};

function CaseListItem({ caseData }: CaseListItemProps) {
  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <StatusAvatar status={caseData.status} />
        </ListItemAvatar>
        <ListItemText
          primary={
            caseData.wayBillNumber && caseData.wayBillNumber.length > 0
              ? caseData.wayBillNumber
              : caseData.fileNames && caseData.fileNames.length > 0
              ? caseData.fileNames.join(", ")
              : caseData.id
          }
          secondary={
            Number(
              moment.utc(moment().diff(moment(caseData.createdAt), "days"))
            ) < 30
              ? moment(caseData.createdAt).fromNow()
              : moment(caseData.createdAt).format("YYYY MM DD")
          }
        />
        <ListItemSecondaryAction>
          {caseData.status === Status.uploaded ||
          caseData.status === Status.processed ? (
            <AbortButton className={styles.button} caseId={caseData.id} />
          ) : null}
          <Link className={styles.Link} to={"/detail/" + caseData.id}>
            <Button
              className={styles.button}
              variant="outlined"
              color="primary"
            >
              Öffnen
            </Button>
          </Link>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
}

export default CaseListItem;
