import React, { useEffect, useState } from "react";
import "./LoginPage.scss";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import { useSetLayoutClass } from "../../styles";
import Main from "../../components/Main";

function LoginPage() {
  const setLayoutClass = useSetLayoutClass();
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    setLayoutClass("login");

    return () => setLayoutClass("");
  }, [setLayoutClass]);

  return (
    <Main className={"login-screen"}>
      <div className="login-screen__container">
        {showReset ? (
          <ResetForm onBackClick={() => setShowReset(false)} />
        ) : (
          <LoginForm
            onResetClick={() => {
              window.analytics.track("Password Reset Clicked");
              setShowReset(true);
            }}
          />
        )}
      </div>
    </Main>
  );
}

export default LoginPage;
